import React from 'react';
import Header from '../components/header';
import GitProfile from '../../src/gitprofile/';

const Marek = () => {

  return (
    <div>
    <Header />
    <GitProfile
      config={{
        github: {
          username: 'marekq',
          sortBy: 'stars',
          limit: 10,
          exclude: {
            forks: true
          },
        },
        social: {
          linkedin: 'marekq',
          twitter: 'marekq',
          email: 'marek@coldstart.dev',
        },
        themeConfig: {
          defaultTheme: 'pastel',
          disableSwitch: true
        },
        enablePWA: true,
        enableDarkMode: true,
        experiences: [
          {
            company: 'Wiz',
            position: 'Product Manager, CTO Office',
            from: 'October 2022',
            to: 'Present',
            companyLink: 'https://wiz.io',
          },
          {
            company: 'Stedi',
            position: 'Software Engineer, Stedi Cloud',
            from: 'October 2021',
            to: 'September 2022',
            companyLink: 'https://stedi.com',
          },
          {
            company: 'Amazon Web Services',
            position: 'Strategic Accounts Solutions Architect',
            from: 'February 2021',
            to: 'September 2021',
            companyLink: 'https://aws.amazon.com'
          },
          {
            company: 'Amazon Web Services',
            position: 'Serverless Specialist Solutions Architect',
            from: 'October 2019',
            to: 'January 2021',
            companyLink: 'https://aws.amazon.com'
          },
          {
            company: 'Amazon Web Services',
            position: 'Startup Solutions Architect',
            from: 'January 2019',
            to: 'October 2019',
            companyLink: 'https://aws.amazon.com'
          },
          {
            company: 'Amazon Web Services',
            position: 'Enterprise Solutions Architect',
            from: 'October 2016',
            to: 'December 2018',
            companyLink: 'https://aws.amazon.com'
          },
          {
            company: 'Royal Dutch Shell',
            position: 'Threat and Vulnerability Analyst, CyberDefence',
            from: 'January 2013',
            to: 'September 2016',
            companyLink: 'https://www.shell.com'
          },
          {
            company: 'KPMG',
            position: 'Ethical Hacker, Security Advisory',
            from: 'January 2012',
            to: 'December 2012',
            companyLink: 'https://www.kpmg.com'
          },
        ],
        externalProjects: [
          {
            title: "A mapping library for the Locator/ID Separation Protocol (LISP)",
            description: "The Locator/ID Separation Protocol (LISP) is a protocol that is currently being developed by a Working Group in the Internet Engineering Task Force (IETF). LISP is a network architecture and a protocol that implements a new approach to addressing and routing on top of regular IP. In a nutshell: LISP separates the ’where’ and the ’who’ in networking and uses a mapping system to couple the routing location (where) and endpoint identifier (who). A non-proprietary Linux implementation of LISP is in development, but there currently is no open source control-plane software. This paper gives a brief overview about the control plane of LISP and the Python library that was developed during the research project.",
            link: "research/rp2.pdf"
          },
          {
            title: "Entry point distribution in overlay networks",
            description: "Overlay networks can provide anonymity on the internet. These anonymity networks are also increasingly used to circumvent local censorship by oppressive regimes and company firewalls. The combination of anonymity and censorship circumvention proves to be a powerful tool for whistleblowers, dissidents, and the fight for free speech in general. As recent as spring 2010, opponents have found methods to prevent users within their realm of influence from accessing such a network by blocking the entry nodes on their own networks. This paper discusses several possible extra distribution vectors and a general method for personalized distribution of entry nodes, making complete enumeration of all entry nodes for a local network impossible and thus ensuring availability to the end-user.",
            link: "research/ssn.pdf"
          },
          {
            title: "Synergy of social networks defeats online privacy",
            description: "The popularity of online social networks is steadily growing and the users are willing to participate in a variety of networks and reveal sensitive personal information.The synergy of social networks can lead to a detailed profile of users. In this paper the risks of social network user profiling are examined. Moreover, risk scenarios are provided concerning the negative effects of data mining social networks. At the end there is a proof of concept regarding the method of crawling social networks.",
            link: "research/rp1.pdf"
          },
          {
            title: "Beating Metasploit with Snort",
            description: "Earlier research has shown that the effectiveness of Snort against the Metasploit Framework is very low. We made an attempt to improve the detection rate by automatically converting modules of the Metasploit Framework to Snort rules. Based on our analysis of the Metasploit modules, we automated the process of generating Snort rules to detect payloads. Our tests have shown that the detection rates increased compared to the numbers based on earlier research. The results look promising, but need some further analysis. Especially encoders are still an unresolved issue.",
            link: "research/ot.pdf"
          },
          {
            title: "Managing IPv6 Address Assignment in Large Scale Networks",
            description: "Large data centers serve a great number of online services. Even in restricted networks, such as computing clusters, servers need to join and leave the LAN on demand to ensure a highly-available service. Virtualization helps administrators to create and replace hundreds of heterogeneous network resources on-the-fly, making their addresses difficult to manage and manual address assignment not scalable. This project investigates current practices in managing IPv6 addresses in large-scale datacenters, comparing stateful and stateless approaches, and highlighting a new framework for managing and transitioning to IPv6 addresses.",
            link: "research/lia.pdf"
          }
        ],
        education: [
          {
            institution: 'University of Amsterdam',
            degree: 'Master of Science - System and Network Engineering',
            url: 'https://www.uva.nl/en',
            from: '2010',
            to: '2011',
          },
          {
            institution: 'Zuyd University of Applied Sciences',
            degree: 'Bachelor of Science - Network Infrastructure Design',
            from: '2006',
            to: '2010',
          },
        ]
      }}
    />
    </div>
  )
}

export default Marek;
